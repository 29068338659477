require('bootstrap');
import '../sass/main.scss';
import {tns} from 'tiny-slider';

if (document.getElementById('customers_slider')) {
	var slider = tns({
		container: '#customers_slider',
		slideBy: 'page',
		autoplay: true,
		autoplayButton: false,
		autoplayButtonOutput: false,
		gutter: 10,
		controlsPosition: 'bottom',
		controlsText: ["&larr;", "&rarr;"],
		nav: false,
		responsive: {
			320: {
				items: 2
			},
			480: {
				items: 3
			},
			1200: {
				items: 5
			}
		}
	});
}